.description {
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #404040;
}

.Description-wrapper {
  margin-top: 25px;
}

@media screen and (min-width: 768px) {
  .description {
    text-align: left;
  }
}

@Media screen and (min-width: 1280px) {
  .description {
    font-size: 16px;
    line-height: 18px;
  }
}