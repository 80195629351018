.Loader {
  position: fixed;
  top: 8.5rem;
  left: 0;
  width: 100vw;
  height: calc(100vh - 8.5rem);
  background-color: #fff;
  z-index: 1337;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .Loader {
    top: 9.5rem;
    height: calc(100vh - 9rem);
  }
}

@media (min-width: 1024px) {
  .Loader {
    top: 13rem;
    height: calc(100vh - 13rem);
  }
}