.Site {
  transform: translate3d(0, 0, 0);
  transition: transform cubic-bezier(0.7, -0.06, 0.225, 1.08) 800ms,
    opacity cubic-bezier(0.7, -0.06, 0.225, 1.08) 300ms;
  padding: 0 1rem;
  margin: 1rem auto;
  height: 100%;
  padding-top: 9rem;
}

.Site--animating {
  transform: translateY(-105%);
}

.Site--fading {
  opacity: 0;
}

@media (min-width: 768px) {
  .Site {
    padding: 0 2rem;
    margin: 1.5rem auto;
    height: 100%;
    padding-top: 10rem;
  }
}

@media (min-width: 1024px) {
  .Site {
    max-width: 80%;
    padding: 0;
    margin: 3rem auto;
    padding-top: 13rem;
  }
}

@media screen and (min-width: 1280px) {
  .App {
    position: relative;
    height: 100%;
  }
}
