.Photo {
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
}

.Photo-video .Video-button-play {
  font-size: 12rem;
}

.Photo-image--text {
  text-transform: uppercase;
  padding: 2rem 1rem 1rem 1rem;
  text-align: center;
  font-size: 1.6rem;
  line-height: 2rem;
  color: #fff;
  overflow-y: auto;
  max-height: 89%;
}

.Photo-div {
  display: flex;
  flex-direction: column;
  position: relative;
}

.Photo-image {
  width: 100%;
  height: 20rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  flex-shrink: 0;
  display: none;
}

.Photo-image--small:first-child {
  margin-bottom: 1rem;
}

.Photo-description {
  margin-bottom: 2rem;
  padding: 0 1.5rem;
  text-align: center;
}

.Photo--shades .Photo-div:first-child .Photo-image {
  padding-bottom: 125%;
}

.Photo--shades .Photo-div:nth-child(2) .Photo-image {
  padding-bottom: 135.6%;
}

.Photo--shades .Photo-div:nth-child(3) .Photo-image {
  padding-bottom: 156.5%;
}

.Photo-video {
  display: block;
  background-color: #111;
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.Photo-img {
  margin-bottom: 1rem;
  width: 100%;
}

.Photo--summer .Photo-div:nth-child(6) {
  order: 0;
}

.Photo--summer .Photo-div:nth-child(1) {
  order: 1;
}

.Photo--summer .Photo-div:nth-child(3) {
  order: 2;
}

.Photo--summer .Photo-div:nth-child(2) {
  order: 3;
}

.Photo--summer .Photo-div:nth-child(7) {
  order: 4;
}

.Photo--summer .Photo-div:nth-child(3) {
  order: 5;
}

.Photo--summer .Photo-div:nth-child(5) {
  order: 6;
}

.Photo--summer .Photo-div:nth-child(4) {
  order: 7;
}

@media screen and (min-width: 768px) {
  .Photo {
    display: block;
    max-height: 40rem;
    min-height: 30rem;
    position: relative;
  }

  .Photo-wrapper {
    display: flex;
    width: 100%;
    margin: 0 auto;
    flex-wrap: wrap;
  }

  .Photo--summer .Photo-wrapper {
    flex-direction: row;
  }

  .Photo--summer .Photo-div:nth-child(6) {
    order: 5;
  }

  .Photo--summer .Photo-div:nth-child(1) {
    order: 0;
  }

  .Photo--summer .Photo-div:nth-child(3) {
    order: 2;
  }

  .Photo--summer .Photo-div:nth-child(2) {
    order: 1;
  }

  .Photo--summer .Photo-div:nth-child(7) {
    order: 6;
  }

  .Photo--summer .Photo-div:nth-child(5) {
    order: 4;
  }

  .Photo--summer .Photo-div:nth-child(4) {
    order: 3;
  }

  .Photo-video .Video-button-play {
    font-size: 9rem;
  }

  .Photo--shades .Photo-wrapper {
    width: 85%;
  }

  .Photo--abstract .Photo-wrapper {
    width: calc(76% + 0.5rem);
    margin: 0;
    height: 100%;
  }

  .Photo--abstract {
    max-height: 30rem;
  }

  .Photo--abstract .Photo-image {
    height: 14rem;
  }

  .Photo--abstract .Photo-video {
    height: 100%;
  }

  .Photo--shades .Photo-div {
    width: 100%;
  }

  .Photo--shades {
    max-height: 100%;
    min-height: auto;
    height: auto;
  }

  .Photo--shades .Photo-wrapper {
    justify-content: center;
  }

  .Photo--shades .Photo-div:first-child {
    flex-basis: 34.55%;
  }

  .Photo--shades .Photo-div:nth-child(2) {
    flex-basis: 31.9%;
  }

  .Photo--shades .Photo-div:nth-child(3) {
    flex-basis: 27.65%;
  }

  .Photo-image {
    width: calc(100% - 0.5rem);
  }

  .Photo-div {
    display: block;
    margin-bottom: 0.5rem;
  }

  .Photo-div--full {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    height: calc(100% + 0.5rem);
    margin-bottom: 0;
  }

  .Photo-div--full .Photo-image {
    height: 100%;
    width: 100%;
  }

  .Photo-image--left {
    position: absolute;
    left: 1rem;
    bottom: 1rem;
    color: #fff;
    font-size: 2rem;
  }

  .Photo-image {
    display: block;
  }

  .Photo-img {
    display: none;
  }

  .Photo-image--small {
    height: 55%;
  }

  .Photo-image--small:first-child {
    margin-bottom: 0.5rem;
    height: 45%;
  }

  .Photo--shades .Photo-wrapper {
    flex-wrap: nowrap;
  }

  .Photo--summer .Photo-div:nth-child(1) {
    width: 20%;
    height: 50%;
  }

  .Photo--summer .Photo-div:nth-child(2) {
    width: 30%;
    height: 50%;
  }

  .Photo--summer .Photo-div:nth-child(3) {
    width: 25%;
    margin-bottom: 1rem;
  }

  .Photo--summer .Photo-div:nth-child(4) {
    height: 50%;
    width: 25%;
  }

  .Photo--summer .Photo-div:nth-child(5) {
    height: calc(50% - 0.5rem);
    width: 23%;
  }

  .Photo--summer .Photo-div:nth-child(6) {
    height: calc(50% - 0.5rem);
    width: 40%;
  }

  .Photo--summer .Photo-div:nth-child(7) {
    width: 36.55%;
  }

  .Photo--abstract .Photo-div:nth-child(1) {
    width: 30%;
    height: 60%;
  }

  .Photo--abstract .Photo-div:nth-child(1) .Photo-image {
    background-position: bottom;
    height: 18rem;
  }

  .Photo--abstract .Photo-div:nth-child(2) {
    width: 23%;
    margin-bottom: 1rem;
  }

  .Photo--abstract .Photo-div:nth-child(2) .Photo-image:nth-child(3) {
    background-position: top;
    height: 55%;
  }

  .Photo--abstract .Photo-div:nth-child(3) {
    width: calc(47% - 1rem);
  }

  .Photo--abstract .Photo-div:nth-child(4) {
    height: 60%;
    width: 38%;
  }

  .Photo--abstract .Photo-div:nth-child(5) {
    height: calc(50% - 0.5rem);
    width: 22%;
  }

  .Photo--abstract .Photo-div:nth-child(6) {
    height: calc(50% - 0.5rem);
    width: calc(40% - 0.5rem);
  }

  .Photo--abstract .Photo-div:nth-child(7) {
    width: 24%;
    height: 32.5rem;
  }

  .Photo--abstract .Photo-div:nth-child(7) .Photo-image {
    background-size: 100% 100%;
  }

  .Photo-video {
    margin-bottom: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  .Photo-video button {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1280px) {
  .Photo-image--text {
    padding: 2rem 2rem 1rem 2rem;
    font-size: 1.8rem;
    line-height: 2rem;
  }

  .Photo--summer .Photo-wrapper {
    width: 94%;
  }

  .Photo--abstract {
    max-height: 40rem;
  }

  .Photo--abstract .Photo-div:nth-child(1) .Photo-image {
    height: 100%;
  }

  .Photo--abstract .Photo-div:nth-child(1) {
    height: 55%;
  }

  .Photo--abstract .Photo-div:nth-child(2) {
    height: 53.9%;
  }

  .Photo--abstract .Photo-div:nth-child(3) {
    height: 55%;
  }

  .Photo--abstract .Photo-div:nth-child(4) .Photo-image,
  .Photo--abstract .Photo-div:nth-child(5) .Photo-image,
  .Photo--abstract .Photo-div:nth-child(6) .Photo-image {
    height: 18rem;
  }

  .Photo--abstract .Photo-div:nth-child(7) {
    height: 40.5rem;
  }
}

@media screen and (min-width: 1440px) {
  .Photo--abstract {
    max-height: 48rem;
  }

  .Photo--abstract .Photo-div:nth-child(7) {
    height: 46.25rem;
  }

  .Photo-video .Video-button-play {
    font-size: 11rem;
  }

  .Photo--abstract .Photo-div:nth-child(4) .Photo-image,
  .Photo--abstract .Photo-div:nth-child(5) .Photo-image,
  .Photo--abstract .Photo-div:nth-child(6) .Photo-image {
    height: 19.35rem;
  }

  .Photo--summer {
    max-height: 55rem;
  }

  .Photo-image {
    height: 24rem;
  }

  .Photo-image--small {
    height: 55%;
  }
}

@media screen and (min-width: 1900px) {
  .Photo--summer {
    max-height: 60rem;
    min-height: 80%;
  }

  .Photo-image {
    height: 32rem;
  }

  .Photo-image--small {
    height: 55%;
  }
}