.Contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.Contact a {
  text-decoration: none;
  font-size: 2rem;
  font-weight: 500;
  color: #404040;
  transition: color 0.2s ease-in;
}

.Contact a:hover {
  color: #999999;
  transition: color 0.2s ease-out;
}

@media screen and (min-width: 768px) {
  .Contact a {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1024px) {
  .Contact {
    height: 75vh;
  }
}