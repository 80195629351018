.SingleFilm {
  height: 100%;
}

.SingleFilm-bckg {
  width: 100%;
  padding-bottom: 56.25%;
  margin: 0 auto;
  position: relative;
}

.SingleFilm-image {
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

@media screen and (min-width: 768px) {
  .SingleFilm-bckg {
    width: 70%;
    padding-bottom: 42%;
  }
}

@media screen and (min-width: 1280px) {
  .SingleFilm-bckg {
    padding-bottom: 40%;
    width: 65%;
  }
}

@media screen and (min-width: 1440px) {
  .SingleFilm-bckg {
    padding-bottom: 36%;
  }
}
