.LandscapeBckgImg {
  position: relative;
  margin: 0 auto;
}

.LandscapeBckgImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

@media screen and (min-width: 768px) {
  .LandscapeBckgImg {
    width: 70%;
  }
}

@media screen and (min-width: 1280px) {
  .LandscapeBckgImg {
    width: 55%;
  }
}